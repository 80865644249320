import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import TextField from 'components/ui-components-v2/TextField';
import Typography, { TypographyProps } from 'components/ui-components-v2/Typography';

import './main.scss';

interface Classes {
    root?: string;
    text?: string;
}
interface Props {
    value: string;
    endIcon?: JSX.Element; // E.g. <Icon fontSize="small">edit</Icon>
    textFieldProps?: any; // Any props to overwrite the default settings and styling of the text field
    valueProps?: any; // Any props to overwrite the default settings and styling the value
    tooltip?: string;
    noEmptyAllowed?: boolean;
    useDebounce?: boolean;
    useDoubleClick?: boolean;
    classes?: Classes;
    isEditing?: boolean;
    variant?: TypographyProps['variant'];
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const TextInlineEdit: React.FC<Props> = ({
    value,
    endIcon,
    textFieldProps,
    valueProps,
    tooltip,
    useDebounce,
    useDoubleClick = false,
    noEmptyAllowed,
    isEditing: isEditingProp,
    classes,
    variant = 'body2',
    onChange,
    onBlur
}) => {
    const [isEditing, setIsEditing] = useState(isEditingProp || false);
    const [inputValue, setInputValue] = useState(value);

    // Sync isEditing state with external prop if is passed
    useEffect(() => {
        if (isEditingProp !== undefined) {
            setIsEditing(isEditingProp);
        }
    }, [isEditingProp]);

    useEffect(() => {
        if (value === inputValue) return;

        setInputValue(value);
    }, [value]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);

        if (noEmptyAllowed && e.target.value === '') return;

        if (useDebounce) {
            debouncedChangeStateUpdate(e.target.value);
        } else {
            onChange(e.target.value);
        }
    };

    const stopEditing = () => {
        if (!inputValue) setInputValue(value);

        setIsEditing(false);
    };

    const handleOnBlur = () => {
        stopEditing();
        onBlur && onBlur();
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    // Close the editing state after pressing enter
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            stopEditing();
        }
    };

    // Only save the new title after the user stopped typing for 500ms
    const debouncedChangeStateUpdate = useCallback(debounce(onChange, 500), []);

    const textInput = (() => {
        if (inputValue === undefined || inputValue === null) return value || '';

        return inputValue;
    })();

    return (
        <div className={classNames('text-inline-edit', classes?.root)}>
            {isEditing ? (
                <TextField
                    value={textInput}
                    onChange={handleInputChange}
                    onBlur={handleOnBlur}
                    autoFocus
                    fullWidth
                    variant="standard"
                    onKeyDown={handleKeyDown}
                    {...textFieldProps}
                />
            ) : (
                <Tooltip enterDelay={400} enterNextDelay={400} title={tooltip ? tooltip : ''}>
                    <Typography
                        variant={variant}
                        className={classNames('text-inline-edit__value', classes?.text)}
                        onClick={!useDoubleClick ? handleEditClick : undefined}
                        onDoubleClick={useDoubleClick ? handleEditClick : undefined}
                        {...valueProps}>
                        {textInput}
                        {endIcon && endIcon}
                    </Typography>
                </Tooltip>
            )}
        </div>
    );
};

export default TextInlineEdit;
