import React, { useState, useEffect } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from 'components/ui-components-v2/List';
import ListItemButton from 'components/ui-components-v2/ListItemButton';
import { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import Icon from 'components/ui-components-v2/Icon';
import GenericIcon from 'components/ui-components/GenericIcon/components';
import Badge from 'components/ui-components-v2/Badge';

export interface SidebarItem {
    title: string;
    description?: string;
    icon: string;
    isCustomSvg?: boolean; // If true, the icon is custom svg icon used in combination with the GenericIcon component.
    type: string; // The unique type of the sidebar item
    badgeCount?: number;
}

interface Props {
    items: SidebarItem[];
    defaultActiveSidebarItem?: string;
    dataCyPrefix?: string;
    onChangeActiveSidebarItem: (type: string) => void;
}

/**
 * This component shows a vertical list of sidebar items to filter the content of the Dialog. It's used in the
 * ContentWithSidebarWrapper component, but can also be imported separately.
 */
const DialogSidebarItems: React.FC<Props> = ({ items, defaultActiveSidebarItem, dataCyPrefix, onChangeActiveSidebarItem }) => {
    const [activeItem, setActiveItem] = useState<string>('');

    // Set the default active item
    useEffect(() => {
        if (defaultActiveSidebarItem) {
            changeActiveItem(defaultActiveSidebarItem);
        }
    }, [defaultActiveSidebarItem]);

    // Set the new active item and call the callback function
    const changeActiveItem = (type: string) => {
        setActiveItem(type);
        onChangeActiveSidebarItem(type);
    };

    return (
        <List>
            {items.map((item) => {
                const isActive = activeItem === item.type;
                const hasBadge = !!item.badgeCount && item.badgeCount > 0;

                return (
                    <ListItemButton
                        dense={false}
                        selected={isActive}
                        data-cy={`${dataCyPrefix}-${item.title}-div`}
                        key={item.type}
                        onClick={() => changeActiveItem(item.type)}>
                        <ListItemIcon>
                            <Badge invisible={!hasBadge} badgeContent={item.badgeCount} color="primary">
                                {item.isCustomSvg ? <GenericIcon icon={item.icon as GenericSvgIcon} /> : <Icon>{item.icon}</Icon>}
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary={item.title} secondary={item.description} />
                    </ListItemButton>
                );
            })}
        </List>
    );
};

export default DialogSidebarItems;
